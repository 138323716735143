<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">订单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc  operationControlWidth"
          style="align-items: flex-start;"
        >
          <div class="searchbox" style="margin-bottom:5px">
            <div title="订单号" class="searchboxItem ci-full">
              <span class="itemLabel">订单号:</span>
              <el-input
                v-model="searchData.orderNumber"
                type="text"
                size="small"
                placeholder="请输入订单号"
                clearable
              />
            </div>
            <div title="订单类型" class="searchboxItem ci-full">
              <span class="itemLabel">订单类型:</span>
               <el-select
                size="small"
                v-model="searchData.orderType"
                clearable
                placeholder="请选择订单类型"
                style="width:100%"
              >
                <el-option
                  v-for="item in orderTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="支付方式" class="searchboxItem ci-full">
              <span class="itemLabel">支付方式:</span>
              <el-select
                size="small"
                v-model="searchData.orderMethod"
                clearable
                placeholder="请选择支付方式"
                style="width:100%"
              >
                <el-option
                  v-for="item in orderMethodList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="下单时间" class="searchboxItem ci-full">
              <span class="itemLabel">下单时间:</span>
              <el-date-picker
                clearable
                style="width: 260px"
                size="small"
                format="yyyy-MM-dd"
                v-model="searchData.orderDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>

            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="Export()"
              >导出</el-button
            >
          </div>
        </div>
        <div style="margin: 15px 0.675rem 5px">
          <span>总金额：{{ projectCount }}元</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="订单号"
                align="left"
                show-overflow-tooltip
                prop="orderSn"
                min-width="150px"
              />
              <el-table-column
                label="订单类型"
                align="left"
                show-overflow-tooltip
                prop="productType"
                min-width="100px"
              >
              <template slot-scope="scope">
                  {{scope.row.productType == '1' ? '题库订单':'课程订单'}}
              </template>
              </el-table-column>
              <el-table-column
                label="金额（元）"
                align="right"
                show-overflow-tooltip
                prop="paymentMoney"
                width="120px"
              />
              <el-table-column
                label="支付方式"
                align="right"
                show-overflow-tooltip
                prop="paymentMethod"
                width="100px"
              >
              <template slot-scope="scope">
                  {{  $setDictionary("ORDER_METHOD", scope.row.paymentMethod)}}
              </template>
              </el-table-column>
              <el-table-column
                label="下单时间"
                align="right"
                show-overflow-tooltip
                 width="180px"
                prop="createTime"
              />
              <el-table-column
                label="支付单号"
                align="right"
                show-overflow-tooltip
                prop="thirdPartPaymentNumber"
                width="260px"
              />
              <el-table-column
                label="支付时间"
                align="right"
                show-overflow-tooltip
                 width="200px"
                prop="payTime"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
const className = "eduCertService";

import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";

export default {
  name: "evaluate/statementList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      searchData: {
        orderNumber: "",
        orderType:'',
        orderMethod: "",
        orderDate: "",
      },
      orderMethodList: [
        {
          value: "1",
          label: "苹果内部支付",
        },
        {
          value: "2",
          label: "银联支付宝支付",
        },
        {
          value: "3",
          label: "银联微信支付",
        },
      ],
      orderTypeList: [
        {
          value: "1",
          label: "题库订单",
        },
        {
          value: "2",
          label: "课程订单",
        },
      ],
      projectCount: "",
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.orderNumber) {
        params.orderSn = this.searchData.orderNumber;
      }
      if (this.searchData.orderType) {
        params.productType = this.searchData.orderType;
      }
      if (this.searchData.orderMethod) {
        params.paymentMethod = this.searchData.orderMethod;
      }
      if (this.searchData.orderDate) {
        params.startDate = this.searchData.orderDate[0];
        params.endDate = this.searchData.orderDate[1];
      }
      this.doFetch({
        url: "/order/main/order-main/financePageList",
        params,
        pageNum,
      },true,2);
      this.getTotal();
    },
    getTotal() {
      let parmar = {};
      if (this.searchData.orderNumber) {
        parmar.orderSn = this.searchData.orderNumber;
      }
      if (this.searchData.orderType) {
        parmar.productType = this.searchData.orderType;
      }
      if (this.searchData.orderMethod) {
        parmar.paymentMethod = this.searchData.orderMethod;
      }
      if (this.searchData.orderDate) {
        parmar.startDate = this.searchData.orderDate[0];
        parmar.endDate = this.searchData.orderDate[1];
      }
      this.$post("/order/main/order-main/financeSum", parmar,3000,true,2)
        .then((ret) => {
          const retData = ret.data;
          this.projectCount = retData;
        })
        .catch((err) => {
          return;
        });
    },
         // 导出
    Export() {
      const params = {
          };
            if (this.searchData.orderNumber) {
        params.orderSn = this.searchData.orderNumber;
      }
      if (this.searchData.orderType) {
        params.productType = this.searchData.orderType;
      }
      if (this.searchData.orderMethod) {
        params.paymentMethod = this.searchData.orderMethod;
      }
      if (this.searchData.orderDate) {
        params.startDate = this.searchData.orderDate[0];
        params.endDate = this.searchData.orderDate[1];
      }
      this.$post(
        "/order/main/order-main/exportFinanceList",
        params,
        3000,
        true,
        2
      ).then((res) => {
        // ;
        if (res.status == "0") {
          window.open(res.data);
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.projectUser {
  min-width: 6rem !important;
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 300px;
    height: 300px;
    background-color: #999; //设置白色背景色
  }
}
.leftData {
  display: flex;
  flex-direction: column;
  img {
    width: 300px;
    height: 300px;
  }
  span {
    display: flex;
    width: 300px;
  }
}

.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.el-divider--horizontal {
  margin: 13px 0;
}
.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;
  .dailyRemind-1 {
    margin-bottom: 20px;
    p {
      margin-top: 10px;
    }
  }
  .dailyRemind-2 {
    p {
      margin-top: 10px;
    }
  }
}
</style>
